.report-instructions {
  padding: 20px 20px;
  background: white;
}

.comparison-progress-row {
  padding-bottom: 10px;
}

.roster-dropdown {
  box-shadow: none;
  text-align: center;
  padding-bottom: 20px;
  background: white;
}