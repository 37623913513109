@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.sans-pro {
  font-family: 'Source Sans Pro', sans-serif;
}

.sans-pro-caps {
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
  font-size: 14px;
}

.sans-pro-header {
  font-family: 'Source Sans Pro', sans-serif;
  color: gray;
  font-size: 2rem;
}

.permanent-marker-goal {
  font-family: 'Permanent Marker', cursive;
}

.modal-form-header {
  color: gray;
  font-size: 20px;
  float: left;
  margin-right: 10px
}

.strengths-header {
  text-align: center;
  margin: 10px 0px 5px 0px;
  background-color: #ececec;
  padding: 3px;
  font-size: 15px;
  border-radius: 5px;
}

.report-img {
  width: 100%
}

.reports-view {
  background-color: white;
  padding: 20px 20px 0px 20px;
  text-align: center;
}

.reports-view-title {
  background-color: white;
  padding: 0px 20px ;
  text-align: left;
  text-transform: capitalize;
}

.reports-view-hr {
  border-bottom: 1px solid darkgray;
}

.reports-container {
  background-color: white;
  padding: 10px;
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.reports-view-heading {
  margin: 0px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}

.hover-pointer {
  cursor: pointer;
}

.goal-scale {
  color: #4b4b4b;
}

.tab-title-container {
  padding-top: 40px;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.tab-title {
  margin-bottom: 0;
  font-size: 1.5rem;
}

.tab-description {
  margin-top: 20px;
}

.reset-button {
  display: inline-block;
  position: absolute;
  right: 20px;
  bottom: -5px;
}
.reset-button-mobile {
  display: inline-block;
  margin-top: 20px;

}

.adaptive-learning-control {
  text-align: right;
}

/* .adaptive-learning-goal-select {
  width: auto;
  display: inline-block;
} */

.activity-link, .activity-link:hover {
  color: inherit; 
  text-decoration: none;
}